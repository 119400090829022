
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.customSuccess {
  border: 1px solid $green;
}

.customError {
  border: 1px solid $red;
}
