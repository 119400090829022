
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
button.closeButton {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 18px;
  right: 16px;
  color: $black;
  transition: color 0.3s linear;
  z-index: 4;
    
  &:focus,
  &:hover {
    color: $grayD;
  }
}
