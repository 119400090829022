
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.inner {
  position: relative;
  display: inline-block;
  padding-right: 30px;
  padding-left: 30px;
  text-transform: none;
}

.spinner {
  position: absolute;
  top: 50%;
  right: 0;
  margin-top: -10px;
}
