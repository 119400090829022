
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../src/style/mixins.scss';

.row {
  border: 1px solid $gray;
  padding: 12px 24px;
  border-radius: 12px;
  width: 100%;
  transition: background 0.3s ease;
  position: relative;
  @include mediaMdMin {
    display: flex;
    align-items: center;
  }

  &:hover, &:focus, &:active {
    background: $pinkLight;
  }
  
  &:hover .edit {
    color: $black;
  }
  
  &:hover .delete {
    color: $pink;
  }

  > a {
    position: absolute;
    inset: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.targetsHolder {
  position: relative;
  z-index: 2;
}

.viewsHolder, .targetsHolder {
  display: flex;
  margin-right: 16px;
}

.titleViews, .titleTargets {
  color: $gray;
  margin-right: 16px;
}

.title {
  font-weight: 400;
}

.meta{
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 1.5rem;
  @include mediaMdMin {
    margin: 0 0 0 auto;
  }
}

.dateHolder {
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-bottom: 10px;
  @include mediaMdMin {
    margin: 0 24px 0 auto;
    width: auto;
  }
}

.titleDate {
  color: $gray;
  margin-right: 16px;
}

.targetsHolder {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 10px;
  @include mediaMdMin {
    margin: 0 0 0 auto;
    width: auto;
  }
}

.titleTarget {
  color: $gray;
  margin-right: 16px;
  transition: color 0.5 ease;
  &:hover {
    color: $pink;
  }
}

.countTarget {
  display: block;
  min-width: 35px;
  text-align: end;
  color: $black;
}

.action {
  margin-left: auto;
  position: absolute;
  right: 10px;
  top: 4px;
  z-index: 3;
  @include mediaMdMin {
    position: static;
    margin-left: 26px;
  }
}

.edit, .delete {
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px;
  position: relative;
  z-index: 2;
  transition: all 0.3s ease;
  
  &:hover {
    transform: scale(1.1);
  }
}

.edit {
  color: $gray;
}

.delete {
  color: $pinkMidle;
}
