
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../src/style/mixins.scss';

.row {
  border: 1px solid $gray;
  padding: 8px 24px 8px 8px;
  border-radius: 12px;
  width: 100%;
  transition: background 0.3s ease;
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  @include mediaMdMin {
    flex-wrap: nowrap;
  }
  
  &:hover, &:focus, &:active, .highlight {
    background: $pinkLight;
  }

  &:hover .edit {
    color: $black;
  }
  
  &:hover .delete {
    color: $pink;
  }
  
  > a {
    position: absolute;
    inset: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
}

.highlight {
  background: $pinkLight;
}

.targetsHolder {
  position: relative;
  z-index: 2;
}

.mediaMultiple {
  display: flex;
  align-items: center;
  gap: 8px;
  position: relative;
  margin-right: 16px;
  width: 128px;
  overflow: hidden;
  flex-shrink: 0;

  li {
    @for $i from 1 through 5 {
      &:nth-child(#{$i}) {
        z-index: 5 - $i;
      }
    }

    &:hover {
      z-index: 6;
    }
  }
}

.mediaMultipleFive {
  li + li {
    margin-left: -29.6%;
  }
}

.mediaMultipleFour {
  li + li {
    margin-left: -24.5%;
  }
}

.mediaMultipleThree {
  li + li {
    margin-left: -14.5%;
  }
}

li {
  .imgBtn {
    display: block;
    border: 1px solid $white;
  }
}

.imgBtn, .videoBtn {
  border: none;
  background: none;
  padding: 0;
  border-radius: 12px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &:hover .img, &:hover .video {
    transform: scale(1.15);
  }
}

.img, .video {
  display: block;
  object-fit: cover;
  border-radius: 12px;
  transition: transform 0.3s ease;
  background: $pinkMidle;
}

.img {
  width: 48px;
  height: 48px;
}

.loadIndicateSuccess,
.loadIndicateError,
.loadIndicateConverting {
  position: absolute;
  right: 5px;
  bottom: 5px;
  z-index: 100;
  cursor: pointer;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $red;

  &:before {
    position: absolute;
    inset: -8px;
    content: "";
  }
}

.loadIndicateSuccess {
  background: $green;
}

.loadIndicateConverting {
  background: $yellow;
}

.video {
  height: 48px;
  width: 72px;
}

.videoBtn {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  video {
    pointer-events: none;
  }
}

.videoIco {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: $white;
}

.descr {
  display: flex;
}

.title {
  font-weight: 400;
  margin-right: 8px;
}

.titleLabel {
  color: $gray;
  display: flex;
  align-items: center;

  &::before {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $gray;
    margin-right: 8px;
  }
}

.meta{
  display: flex;
  align-items: center;
  width: 100%;
  gap: 8px 24px;
  flex-wrap: wrap;
  margin-top: 8px;
  @include mediaMdMin {
    gap: 24px;
    flex-wrap: nowrap;
    width: auto;
    margin-left: auto;
    margin-top: 0;
  }
}

.action {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  margin-left: auto;
  @include mediaMdMin {
    margin-left: 0;
  }
}

.dateHolder {
  display: flex;
  margin-right: 24px;
  margin-top: 8px;
  @include mediaMdMin {
    margin-top: 0;
  }
}

.titleDate {
  color: $gray;
  margin-right: 16px;
}

.titleDate {
  color: $gray;
  margin-right: 16px;
}

.ratingHolder {
  display: flex;
  align-items: flex-start;
  min-width: 64px;
  color: $yellow;
}

.rating {
  color: $black;
  margin-left: 8px;
}

.viewsHolder, .targetsHolder {
  display: flex;
}

.titleViews, .titleTargets {
  color: $gray;
  margin-right: 16px;
}

.titleTargets {
  transition: color 0.5 ease;
  &:hover {
    color: $pink;
  }
}

.countViews, .countTargets {
  display: block;
  min-width: 35px;
  text-align: end;
  color: $black;
}

.edit, .delete {
  border: none;
  background: none;
  cursor: pointer;
  padding: 8px;
  transition: all 0.3s ease;
  position: relative;
  z-index: 2;
  
  &:hover {
    transform: scale(1.1);
  }
}

.edit {
  color: $gray;
}

.delete {
  color: $pinkMidle;
}

.imgPopup, .videoPopup {
  width: 100%;
  height: auto;
  aspect-ratio: 1/0.635;
  display: block;
  object-fit: cover;
  border-radius: 20px;
}
