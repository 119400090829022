
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.wrapper {
    position: absolute;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;
}
  
.emptyWrapper {
  margin: 0 auto;
  padding: 18px;
  margin-bottom: 16px;
}
  
.emptySvg {
  fill: $pink;
}

.title {
  display: block;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  text-transform: uppercase;
  color: $pink;
}
