
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../src/style/mixins.scss';

.switchHolder {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
}

.targetsCount {
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 10px;
  font-weight: 500;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $pink;
  color: $white;
  padding: 2px;
  border-radius: 12px;
  min-width: 18px;
}

.uploadCount {
 margin-left: 20px;
}

.btnHolder {
  position: relative;
}

.btnArrow {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 1rem;
  width: 2rem;
  height: 2rem;
  right: -32px;
  border: 1px solid $gray;
  background: $white;
  padding: 2px;
  border-radius: 50%;
  z-index: 100;
  cursor: pointer;
  transition: all 0.3s ease;
  color: $pink;
  display: none;

  @include mediaSmMax {
    right: -12px;
  }

  &:hover {
    color: $white;
    background: $pink;
  }

  &.visible {
    display: block;
  }
}

.svgArrow {
  width: 100%;
  height: 100%;
}

.targetsListLong {
  border-top: 1px solid $gray;
  border-bottom: 1px solid $gray;
}

.targetsList {
  list-style: none;
  margin: 0 -30px 12px;
  padding: 0 30px;
  max-height: 298px;
  overflow: auto;

  @include mediaSmMin {
    max-height: 285px;
  }

  @include mediaMdMin {
    margin: 0 -40px 12px;
    padding: 0 40px;
  }

  li {
    display: flex;
    position: relative;
    z-index: 1;
    flex-wrap: wrap;
    border: 1px solid $gray;
    border-radius: 12px;
    padding: 16px;
    margin-top: 10px;

    @include mediaSmMin {
      border-radius: 0;
      margin-top: 0;
      padding: 0;
      flex-wrap: nowrap;
      border-width: 0;
    }

    +li {
      @include mediaSmMin {
        border-top: 1px solid #d1d1d1;
      }
    }

    >* {
      padding-top: 8px;
      padding-bottom: 8px;
    }

    &::before {
      z-index: -1;
      position: absolute;
      inset: 4px -30px;
      content: "";
      background: #ff0000;
      transition: all 0.3s ease;
      opacity: 0;

      @include mediaMdMin {
        inset: 4px -40px;
      }
    }

    &:hover {
      &::before {
        opacity: 0.1;
      }
    }
  }
}

.dragVideo,
.dragPhoto {
  display: flex;
  align-items: center;
  // justify-content: center;
  width: 50%;
  padding-right: 16px;
  flex-shrink: 0;

  @include mediaSmMin {
    border-right: 1px solid $gray;
    margin-right: 16px;
    width: 26.5%;
  }

  &:hover {
    cursor: grab;
  }

  &:active {
    cursor: grabbing;
  }
}

.disableListItem {
  opacity: 0.4;
}

.btnRemove,
.btnDrag {
  background: none;
  border: none;
  color: $gray;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: $pink;
  }

  &:disabled {
    cursor: auto;
    pointer-events: none;
  }
}

.btnDrag {
  cursor: grab;
  transition: color 0.3s ease;

  &:active,
  &:hover {
    color: $pink;
    cursor: grabbing;
  }

  &:disabled {
    cursor: auto;
  }

}

.placeholder {
  position: relative;
  width: 56px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  background: $pinkMidle;

  &:before {
    content: "";
    position: absolute;
    left: 0;
    rotate: 10deg;
    top: -20px;
    width: 10px;
    bottom: -20px;
    background: $white;
    opacity: 0.5;
    animation: jump 2s infinite;
  }
}

@keyframes jump {
  0% {
    transform: translateX(-56px);
  }
  100% {
    transform: translateX(112px);
  }
}

.image {
  position: relative;
  width: 56px;
  height: 40px;
  border-radius: 8px;
  overflow: hidden;
  flex-shrink: 0;
  background: $pinkMidle;

  video,
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .btnEdit {
    cursor: pointer;
    position: absolute;
    top: 12px;
    left: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    color: $white;
    padding: 0;
  }
}

.loadIndicateSuccess,
.loadIndicateError,
.loadIndicateConverting {
  position: absolute;
  right: 3px;
  bottom: 3px;
  z-index: 100;
  cursor: pointer;
  display: block;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: $red;

  &:before {
    position: absolute;
    inset: -8px;
    content: "";
  }
}

.loadIndicateSuccess {
  background: $green;
}

.loadIndicateConverting {
  background: $yellow;
}

.dragHolder {
  width: 100%;
  border: 1px solid $gray;
  border-radius: 12px;
  padding: 8px;
  background: $white;

  >div {
    margin: 0;
  }
}

.nameField {
  flex: 1;

  input[type="text"] {
    font-size: 10px;
    font-weight: 500;
    height: 24px;
    padding: 4px 12px;
    border-radius: 6px;
    background: $white;
  }

  fieldset {
    border-radius: 6px !important;
  }
}

.actions {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;

  @include mediaSmMin {
    width: auto;
  }
}

.disabled {
  cursor: default !important;
}
