
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.card {
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid $gray;
  padding: 18px;
  border-radius: 12px;
  width: 100%;
  gap: 8px;
}

.icon {
  color: rgba(0, 0, 0, 0.5) !important;
}

.title {
  display: block;
  font-style: 10px;
  font-weight: 500;
  line-height: 0.875;
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $black;
  color: $white;
  font-size: 14px;
  padding: 8px;
  border-radius: 12px;
  min-width: 33px;
  height: 24px;
}

.btn {
  border: none;
  margin-top: 16px;
}
