
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.component {
  position: relative;

  [class*="-root-"] {
    margin-bottom: 0 !important;
  }
}
