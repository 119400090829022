
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../src/style/mixins.scss';

.component {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px 0 0;
  margin-top: auto;

  @include mediaMdMin {
    padding: 16px 0;
  }
}

.action {
  display: flex;
  align-items: center;
  gap: 24px;
  justify-content: center;
}

.button {
  display: flex;
  align-items: center;
  border: none;
  background: none;
  margin: 0;
  padding: 0;
  transition: color 0.3s ease;

}

.buttonActive {
  cursor: pointer;
}

.grayBtn {
  color: $gray;
}

.changeHolder {
  display: flex;
  align-items: center;
  gap: 24px;
}

.descr {
  display: none;
  @include mediaSmMin {
    color: $gray;
    display: block;
  }
}

.number {
  background: none;
  border: none;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: $black;
  }
}

.black {
  color: $black;
}
  
.gray {
  color: $gray;
}

.select {
  border: none;
  outline: none;
  background: none;
  cursor: pointer;
  padding: 8px 16px;
  padding-right: 30px;
  appearance: none;
  -webkit-appearance: none;
  background: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 9.99984L16.59 8.58984L12 13.1698L7.41 8.58984L6 9.99984L12 15.9998L18 9.99984Z" fill="black"/></svg>') no-repeat right -4px center;
}
