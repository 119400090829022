
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.component {
  color: $pink;
  transition: color 0.15s ease;
  display: block;
  text-align: end;
  font-size: 14px;
  font-weight: 400;
}
