
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../src/style/mixins.scss';

.component {
  padding: 74px 15px 12px;
  background: $white;
  @include mediaLgMin {
    padding: 24px 32px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap:16px;

  @include mediaMdMin {
    flex-direction: row;
  }
}

.table {
  margin-top: 40px;
}

.eventsHolder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
