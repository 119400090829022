
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.component {
  width: 100%;
  padding: 24px 32px 32px;
  background-color: $white;
  border-radius: 5px;
  overflow: hidden;
  position: relative;
}
