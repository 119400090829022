
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.breadcrumb {
  ul {display: flex;
    align-items: center;
    gap: 12px;
  }
}

.breadcrumbList {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.link {
  display: flex;
  align-items: normal;
  justify-content: center;
  color: $black;
  padding: 3px 5px;
  border-radius: 12px;

  &:hover {
    border-radius: 12px;
  }
}
  
.breadcrumbItem {
  display: flex;
  align-items: center;
  justify-content: center;
  list-style: none;

  &:hover {
    background: none;
  }
}
  
.separator {
  margin: 0 8px;
}

.title {
  margin-left: 12px;
  font-size: 10px;
  font-weight: 500;
}

.disabledLink {
  cursor: not-allowed;
}
