
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../../src/style/mixins.scss';

.aside {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.logo {
  display: none;
  @include mediaLgMin {
    display: block;
  }

  .logoVide {
    width: 60px;
    height: auto;
    @include mediaMdMin {
      width: 120px;
    }
  }
}

.menu {
  margin-top: 48px;
  
  li {
    font-size: 14px;
  }
}

.link {
  color: rgb(255, 255, 255, 0.5);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  text-decoration: none;
  transition: color 0.3 linear;
  width: 100%;

  & > svg {
    margin-right: 12px;
  }

  &:hover {
    color: $white;
  }
}

.active {
  color: $white;

  & > svg {
    color: $white;
  }
}

.createBtn {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%;
  padding: 0;
  background: transparent;
  border: none;
  cursor: pointer;
  color: rgb(255, 255, 255, 0.5);
  transform: color 0.3s ease;

  &:hover,
  &:active {
    color: $white
  }
}

.userBar {
  margin-top: auto;
  color: $white;
}
