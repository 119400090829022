
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
$borderColor: darken($grayXL, 4.5);
@import '../../../src/style/mixins.scss';


html {
  height: 100%;
}

body {
  display: flex;
  min-height: 100%;
}

:global(#root) {
  display: flex;
  width: 100%;
}

.component {
  display: flex;
  width: 100%;
}

.asideBar {
  padding-top: 74px;
  width: 100%;
  max-width: 218px;
  padding: 24px;
  z-index: 200;
  background-color: $black;
  position: fixed;
  height: 100%;
  overflow: hidden;
  transform: translateX(-100%);
  transition: all .3s ease;
  @include mediaLgMin {
    transform: translateX(0);
  }

  ~ .main {
    @include mediaLgMin {
      padding-left: 248px;
    }
  }
}

.asideBarOpened {
  transform: translateX(0);
}

.main {
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100%;
  background-color: $white;
  &::before {
    content: "";
    position: fixed;
    inset:0;
    background-color: rgba(0, 0, 0, 0.3);
    transition: all 0.6s ease;
    opacity: 0;
    z-index: 199;
    visibility: hidden;
  }
}

.mainLayout {
  &::before {
    visibility: visible;
    opacity: 1;
  }
}

.header {
  flex: 0;
  z-index: 1;
  box-shadow: 0px 3px 28px 0px $borderColor;
}

.mobileheader {
  position: fixed;
  left: 0;
  top: 0;
  z-index: 201;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 15px;
  background: $black;
  @include mediaLgMin {
    display: none;
  }

  img {
    display: block;
  }
}

.btnMenu {
  position: relative;
  display: block;
  font-size: 0;
  width: 24px;
  height: 24px;
  z-index: 101;
  border: none;
  background: none;

  span,
  &::before,
  &::after {
    background: $pink;
    border-radius: 4px;
    position: absolute;
    top: 50%;
    left:0;
    right: 0;
    height: 2px;
    margin-top: -1px;
    transition: all 0.3s linear;
  }

  &::before,
  &::after {
    content: "";
  }
  
  &:before {
    top: 25%;
  }

  &::after {
    top: 75%;
  }
}

.btnMenuOpened {
  span {
    opacity: 0;
  }

  &:before,
  &:after {
    transform: rotate(45deg);
    top: 50%;
    left: 0;
    right: 0;
  }

  &:after {
    transform: rotate(-45deg);
  }
}

.mobileLogo {
  flex-shrink: 0;
}

.logoVide {
  width: 85px;
  height: auto;
}

.content {
  flex: 1;
  display: flex;
  flex-direction: column;
  background-color: $white;
}
