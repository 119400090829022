
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.deleteHolder {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap:16px;
    margin-bottom: 32px;
  }
  
  .deleteIco {
    color: $gray;
  }
  
  .question {
    font-weight: 400;
    font-style: 14px;
  }

.cancelBtn {
  width: 100%;
  padding-top: 12px;
  padding-bottom: 12px;
}
  