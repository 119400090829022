
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../src/style/mixins.scss';

.component {
  display: flex;
  flex-direction: column;
  padding: 74px 15px 12px;
  background: $white;
  min-height: 100%;
  @include mediaLgMin {
    padding: 24px 32px;
  }
}
  
.btn {
  border: none;
  margin-left: auto;
}
    
.titleHolder {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
}
  
.date {
  color: $gray;
  display: flex;
  align-items: center;
    
  &::before {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $gray;
    margin-right: 12px;
    display: block;
  }
}

.count {
  display: flex;
  align-items: center;
  justify-content: center;
  background: $pink;
  color: $white;
  font-size: 14px;
  padding: 8px;
  border-radius: 12px;
  min-width: 32px;
  height: 24px;
}

.listHolder {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}

.loader {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}

.noData {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
}
  