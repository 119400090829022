
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import "../../../src/style/mixins.scss";

.container {
  position: static;
  overflow: hidden;
  height: 50%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  @include mediaMdMin {
    flex: 1;
    height: 100%;
  }
}

.frame {
  display: flex;
  flex-direction: column;
  padding: 20px;
  gap: 20px;
  border: 6px solid #2e2e2e;
  background-color: $white;
  position: relative;
  max-width: calc(100% - 71px);
}

video.preview {
  position: absolute;
  display: block;
  top: 20px;
  left: 20px;
  width: calc(100% - 37px);
  height: calc(100% - 78px);
  background: $white;
  object-fit: cover;
  @include mediaMdMin {
    height: calc(100% - 95px);
    width: calc(100% - 38px);
  }
}

.preview {
  max-height: calc(45dvh - 100px);
  @include mediaMdMin {
    max-width: calc(45dvw - 52px);
    max-height: calc(90dvh - 100px);
  }
}

.logoVide {
  width: 60px;
  height: auto;
  @include mediaMdMin {
    width: 100px;
  }
}

.logo {
  width: 20px;
  height: auto;
  @include mediaMdMin {
    width: 35px;
  }
}

.dialogContent {
  display: flex;
  flex-direction: column;
  padding: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  @include mediaMdMin {
    flex-direction: row;
  }
}

button.closeBtn {
  position: absolute;
  z-index: 99;
  color: $white;
  border-radius: 50%;
  right: 0;
  top: 8px;
  padding: 12px;
}
