
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import "./reset.scss";
@import "./fonts.scss";

@-ms-viewport {
  width: device-width;
}

html {
  -ms-overflow-style: scrollbar;
}

body {
  overflow-x: hidden;
  font-family: $bodyFont;
  font-size: 16px;
  line-height: 1.358;
  word-wrap: break-word;
  color: $black;
  background-color: $white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  color: $black;
}

