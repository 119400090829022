
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.nonFieldError {
  margin-top: 24px;
}

.progress {
  margin-top: 30px;
}

.dropzone {
  border: 1px dashed #D1D1D1;
  text-align: center;
  font-size: 0.625rem;
  line-height: 0.875;
  font-weight: 500;
  border-radius: 8px;
  padding: 12px 0;
  white-space: nowrap;
  cursor: pointer;

  &:before {
    content: "";
    background: url('data:image/svg+xml,<svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M2.66667 8.99967H6.66667V4.99967H9.33333L4.66667 0.333008L0 4.99967H2.66667V8.99967ZM0 10.333H9.33333V11.6663H0V10.333Z" fill="black"/></svg>');
    width: 10px;
    height: 12px;
    display: inline-block;
    flex-shrink: 0;
    vertical-align: middle;
    margin-right: 6px;
  }
}

.uploadInput {
  &:disabled {
    opacity: 0.2;
  }
}
