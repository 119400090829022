
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.nonFieldError {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px 20px;

  p {
    border: 1px solid $red;
    background-color: $white !important;
    background: linear-gradient(rgba(215, 0, 89, 0.1), rgba(215, 0, 89, 0.1));
    padding: 12px 24px 12px 12px;
    margin: 0;
    border-radius: 12px;
    display: flex;
    align-items: center;
    color: $black;

    &:before {
      content: "";
      background: url('data:image/svg+xml,<svg width="24" height="24" viewBox="0 0 24 24" fill="%23d70059" xmlns="http://www.w3.org/2000/svg"><path d="M14.59 8L12 10.59L9.41 8L8 9.41L10.59 12L8 14.59L9.41 16L12 13.41L14.59 16L16 14.59L13.41 12L16 9.41L14.59 8ZM12 2C6.47 2 2 6.47 2 12C2 17.53 6.47 22 12 22C17.53 22 22 17.53 22 12C22 6.47 17.53 2 12 2ZM12 20C7.59 20 4 16.41 4 12C4 7.59 7.59 4 12 4C16.41 4 20 7.59 20 12C20 16.41 16.41 20 12 20Z" fill="%23F30A0A"/></svg>');
      width: 24px;
      height: 24px;
      display: block;
      margin-right: 10px;
      flex-shrink: 0;
    }
  }
}

.submitButton {
  margin-top: 24px;
}
