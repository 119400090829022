
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.spinnerholder {
  position: absolute;
  inset: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 100;
  border-radius: 12px;
  height: 100%;
}

.spinner {
  width: 40px;
  aspect-ratio: 1;
  border-radius: 50%;
  background: conic-gradient(#0000 10%, $pink);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 2px), #000 0);
  animation: rotation 1s infinite linear;
  display: block;
}

@keyframes rotation {
  to {
    transform: rotate(1turn);
  }
}
