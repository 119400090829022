
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.nonFieldError {
  margin: 18px 0;
}

.swichHolder {
  margin-top: 20px;
}

.submitButton {
  margin-top: 24px;
}
