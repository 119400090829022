
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import "../../../../src/style/mixins.scss";

.asideBar {
  li {
    display: block;
    width: 100%;
  }

  .MuiButton-text {
    padding: 0;
  }
}

button.closeButton {
  position: absolute;
  top: -34px;
  right: 0;
  color: $white;
  transition: color 0.3s linear;
  @include mediaMdMin {
    top: -44px;
    right: -44px;
    margin-top: 15px;
  }

  &:focus,
  &:hover {
    color: $grayD;
  }
}

button.button,
button.buttonOut {
  width: 100%;
  display: flex;
  align-items: center;
  color: $white;
  text-transform: none !important;
  justify-content: flex-start;

  .icon {
    margin-right: 10px;
    width: 24px;
    height: 24px;
  }
}

button.buttonOut {
  color: rgb(255, 255, 255, 0.5) !important;
  transition: color 0.3s linear !important;

  &:focus,
  &:hover {
    color: $white !important;
  }
}
