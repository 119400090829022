
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.tabsContent {
  display: none;
  flex-direction: column;
  flex: 1;
}

.tabsContentActive {
  display: flex;
  flex-direction: column;
  flex: 1;
}
