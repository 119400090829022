
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.tabsContent {
  flex-direction: column;
  background: $white;
  min-height: 100%;
  display: none;
}
