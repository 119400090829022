$bodyFont: "Rubik", sans-serif;

$black: #000;
$grayXD: #262626;
$gray: #d1d1d1;
$grayD: #6b6a6a;
$grayL: #f8f9fc;
$grayXL: #f5f6fb;
$white: #ffffff;

$red: #e24e38;

$blue: #4e73df;
$blueD: #2e59d9;
$blueXD: #686fc9;
$blueL: #acb2fa;
$blueXL: #f0f1f6;

$pink: #d70059;
$pinkX: rgba(255, 113, 149, 0.9);
$pinkMidle: rgba(255, 113, 149, 0.3);
$pinkLight: rgba(255, 113, 149, 0.1);
$grayMidle: rgba(0, 0, 0, 0.5);
$grayLight: rgba(0, 0, 0, 0.3);
$green: #4db832;
$yellow: #FFB400;
