
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.formHolder {
  position: relative;
  overflow: hidden;
}

.nonFieldError {
  margin: 18px 0;
}
  
.submitButton {
  margin-top: 18px;
}
