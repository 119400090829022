
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../src/style/mixins.scss';

.holder {
    @include mediaMdMin {
        padding: 20px;
    }
}
