
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.component {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  padding: 200px 0 0;
  background-color: $black !important;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 1)),
    url("../../assets/images/paralax-mob.png") no-repeat center 0 / cover;

  @media only screen and (min-width: 480px) {
    padding: 78px 0;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (min-width: 768px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 1)),
      url("../../assets/images/paralax-web.png");
  }
}

.center {
  width: 100%;
  max-width: 529px;
  margin-top: auto;
  text-align: center;

  @media only screen and (min-width: 480px) {
    margin-top: 0;
  }
}

.textHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
}

.text {
  line-height: 1.35;
  text-align: center;
}

.confirmstionText {
  font-style: 16px;
  font-weight: 500;
  margin-top: 24px;
  margin-bottom: 8px;
}

.link {
  display: block;
  text-align: center;
  color: $pink;
}
