
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.listHolder {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;
  margin-bottom: 24px;
}
