
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.nonFieldError {
  margin: 20px 0;
}

.formField {
  & + & {
    margin-top: 15px;
  }
}

.submitButton {
  margin-top: 24px;
}
