
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.component {
  @include resetButtonStyle;
  --size: 24px;

  width: var(--size);
  height: var(--size);
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 10px;
  top: 25px;
  margin-top: calc(var(--size) / -2);
  color: $pink;
}
