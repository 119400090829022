
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.component {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 200px 0 0;
  background-color: $black !important;
  background: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 1)),
    url("../../assets/images/paralax-mob.png") no-repeat center 0 / cover;

  @media only screen and (min-width: 480px) {
    padding: 78px 0;
  }

  @media only screen and (min-width: 768px) {
    background-image: linear-gradient(rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 1)),
      url("../../assets/images/paralax-web.png");
  }
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 579px;
  margin: auto;
}

.headline {
  display: flex;
  flex-direction: column;
  gap: 18px;
  max-width: 191px;
  align-items: center;
}

.headlineSecond {
  max-width: 159px;
}

.links {
  margin-top: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;

  p {
    margin-right: 5px;
  }
}

.confirmstionText {
  font-style: 16px;
  font-weight: 500;
  margin-top: 24px;
}
