
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../../src/style/mixins.scss';

.component {
  display: flex;
  flex-direction: column;
  padding: 74px 15px 12px;
  background: $white;
  min-height: 100%;
  @include mediaLgMin {
    padding: 24px 32px;
  }
}

.btn {
  border: none;
  margin-left: auto;
}
  
.titleHolder {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 20px;
  flex-wrap: wrap;
  @include mediaMdMin {
    flex-wrap: nowrap;
  }
}

.date {
  color: $gray;
  display: flex;
  align-items: center;
  
  &::before {
    content: "";
    width: 3px;
    height: 3px;
    border-radius: 50%;
    background-color: $gray;
    margin-right: 12px;
    display: block;
  }
}

.tabHolder {
  display: flex;
  gap: 24px;
  margin-bottom: 24px;
}

.tabBtn {
  position: relative;
  border: none;
  cursor: pointer;
  background: transparent;
  font-weight: 500;
  font-size: 16px;
  width: 100%;
  padding-bottom: 8px;
  overflow: hidden;
  transition: color 0.5s ease;
  font-family: inherit;
  
  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: -5px;
    left: -5px;
    height: 2px;
    background-color: $pink;
    transition: transform 0.5s ease;
    transform: scale(0, 1);
  }
  
  &.active,
  &:hover {
    color: $pink;

    &::after {
      transform: scale(1, 1);
    }
  }
}

.holder {
  position: relative;
  flex: 1;
}

.listHolder {
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 24px;
}
