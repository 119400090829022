
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.switch {
  display: inline-flex;
  align-items: center;
  overflow: hidden;
  gap: 8px;

  --gap: 2px;
  --size: 10px;

  .label {
    font-size: 10px;
    font-weight: 500;
  }

  .labelBig {
    font-size: 14px;
  }
    
  input {
    position: fixed;
    left: -9999px;

    &:checked + .slider {
      &:after {
        left: calc(100% - var(--size) - var(--gap));
        background-color: $pink;
      }
    }
  }
  
  .slider {
    width: 26px;
    height: 16px;
    background-color: rgba(245, 245, 245, 1);
    border: 1px solid $gray;
    border-radius: var(--size);
    position: relative;
    cursor: pointer;
    transition: background-color 0.3s;
  
    &:after {
      content: '';
      width: var(--size);
      height: var(--size);
      background-color: $gray;
      border-radius: 50%;
      position: absolute;
      top: var(--gap);
      left: var(--gap);
      transition: all 0.3s ease;
    }
  }
}

.nonFieldError {
  margin: 18px 0;
}
  
.submitButton {
  margin-top: 24px;
}
  
  