@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Bold.ttf") format("truetype");

  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("../assets/fonts/Roboto-Regular.ttf") format("truetype");

  font-weight: 400;
  font-style: normal;
}

@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
