
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.customButton {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  border-radius: 12px;
  border: none;
  padding: 8px 24px;
  color: $white;
  font-size: 14px;
  line-height: 1.75;
  cursor: pointer;
  transition: all 0.3s ease;
  position: relative;
  z-index: 1;
  font-family: inherit;
  
  &.primary {
    background-color: $pink;
    color: #fff;
    transition: all 0.3s linear;

    &:before {
      transition: inherit;
      border-radius: inherit;
      position: absolute;
      inset: 0;
      content: "";
      z-index: -1;
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
      opacity: 0;
    }
  
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  
    &:disabled {
      background-color: rgba(215, 0, 89, 0.1);
      color: rgba(215, 0, 89, 0.2);
      cursor: not-allowed;

      &:hover {
        background-color: rgba(215, 0, 89, 0.1) !important;
        color: rgba(215, 0, 89, 0.2);
        background: rgba(215, 0, 89, 0.1);
      }
    }
  }
  
  &.secondary {
    background-color: rgba(215, 0, 89, 0.2);
    color: $pink;
    transition: all 0.3s linear;

    &:before {
      transition: inherit;
      border-radius: inherit;
      position: absolute;
      inset: 0;
      content: "";
      z-index: -1;
      background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2));
      opacity: 0;
    }
  
    &:hover {
      &:before {
        opacity: 1;
      }
    }
  
    &:disabled {
      background-color: rgba(215, 0, 89, 0.1);
      color: rgba(215, 0, 89, 0.2);
      cursor: not-allowed;

      &:hover {
        background-color: rgba(215, 0, 89, 0.1) !important;
        color: rgba(215, 0, 89, 0.2);
        background: rgba(215, 0, 89, 0.1);
      }
    }

  }
  
  &.text {
    padding: 0;
  }
}

// &.secondary {
//   background-color: rgba(215, 0, 89, 0.2);
//   color: $pink;
//   border: 1px solid rgba(215, 0, 89, 0.2);

//   &:hover {
//     background-color: rgba(215, 0, 89, 0.2) !important;
//     background: linear-gradient(rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2))
//   }

//   &:disabled {
//     background-color: rgba(215, 0, 89, 0.1);
//     color: rgba(215, 0, 89, 0.2);
//     cursor: not-allowed;

//     &:hover {
//       background-color: rgba(215, 0, 89, 0.1) !important;
//       color: rgba(215, 0, 89, 0.2);
//       background: rgba(215, 0, 89, 0.1);
//     }
//   }
