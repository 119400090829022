
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
.component {
  padding: 50px 24px;
}

.section + .section {
  margin-top: 24px;
}

.formAppend {
  margin-top: 15px;
}