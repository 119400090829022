
              @import "/app/src/style/variables.scss";
              @import "/app/src/style/mixins.scss";
            
@import '../../../../src/style/mixins.scss';

.form {
  @include mediaMdMin {
    padding: 20px;
  }
}

.dragHolder {
  width: 100%;
  border: 1px solid $gray;
  border-radius: 12px;
  padding: 8px;
  margin-bottom: 16px;

  > div {
    margin: 0;
  }
}

.inputHolder {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.eventEditHolder {
  display: flex;
  gap: 16px;

  > div {
    flex: 1;

  }

  > button {
    align-self: flex-end;
    height: 50px;
    min-width: 92px;
    white-space: nowrap;
  }
}

.nonFieldError {
  margin: 18px 0;
}

.submitButton {
  margin-top: 25px;
}
